<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Mutasi Deposito</strong>
      </template>
      <div class="row pl-3 pr-3 pt-3 pb-3">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Rekening Lama</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="input.ao_id"
                style="width: 200px;"
              >
                <a-select-option value="1">Tunai</a-select-option>
                <a-select-option value="2">Tabungan</a-select-option>
                <a-select-option value="3">PB</a-select-option>
              </a-select>
            </div> -->
            <!-- v-if="action !== 'create'" -->
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Tgl Transaksi</label>
            </div>
            <!-- v-if="action !== 'create'" -->
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-date-picker
                style="width: 125px;"
                v-model="input.tgl"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Rekening</label>
            </div>
            <!-- v-if="action !== 'create'" -->
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                ref="rekening"
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 110px;"
                v-model.lazy="norekening"
                placeholder="No Rekening"
                v-on:keydown.enter.native="checkRekening1"
              />
              <a-select
                label-in-value
                show-search
                class="ml-2"
                :value="selectedName"
                placeholder="Ketikkan Nama Nasabah"
                style="width: 200px;"
                :filter-option="false"
                :not-found-content="fetching ? 'Searching...' : 'Not Found!'"
                @search="fetchUser"
                @change="(e) => handleChange(e, 'selectedName')"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="(d, index) in dataRekening"
                  :key="index"
                >
                  <!-- {{ d.rekening + ' - ' + d.nama }} -->
                  {{ d.nama + " - " + d.rekening }}
                </a-select-option>
              </a-select>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Nama Nasabah</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                placeholder="Nama Nasabah"
                v-model="maskInput.namanasabah"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Alamat Nasabah</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                placeholder="Alamat Nasabah"
                v-model="maskInput.alamatnasabah"
                :readOnly="true"
              ></a-input>
            </div>
            <!-- <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">No. Bukti</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                placeholder="No. Bukti"
                style="width: 200px;"
                v-model="maskInput.no_bukti"
                :readOnly="true"
              ></a-input>
            </div> -->
            <!-- <div class="col-sm-3 col-md-3 col-lg-3 mt-4">
              <label class="mt-1">Jumlah Hari</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-4">
              <cleave
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="width: 70px; text-align: right;"
                v-model="maskInput.jumlah_hari"
                placeholder="0"
              />
              Hari
            </div> -->
            <div class="col-sm-3 col-md-3 col-lg-3 mt-4">
              <label class="mt-1">Pencairan</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-4">
              <a-select
                class=""
                style="width: 200px;"
                v-model="maskInput.pencairan"
                @change="pencairanChange"
              >
                <a-select-option value="pokok">Pokok</a-select-option>
                <a-select-option value="bunga">Bunga</a-select-option>
                <a-select-option value="bungapokok"
                  >Bunga + Pokok</a-select-option
                >
              </a-select>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Nominal</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <cleave
                v-if="allowEditNominal"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :key="componentKey"
                :raw="true"
                style="width: 150px; text-align: right;"
                v-model="maskInput.nominal"
                placeholder="0"
                @input="hitungTotal"
              />
              <a-input
                v-else
                :key="componentKey1"
                placeholder="0"
                style="width: 150px; text-align: right;"
                v-model="maskInput.nominal"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Bunga</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1" :key="componentKey">
              <cleave
                :key="componentKey"
                v-if="allowEditBunga"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="width: 150px; text-align: right;"
                v-model="input.bunga"
                placeholder="0"
                @input="hitungTotal"
              />
              <a-input
                :key="componentKey1"
                v-else
                placeholder="0"
                style="width: 150px; text-align: right;"
                v-model="input.bunga"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Pajak</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <cleave
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="width: 150px; text-align: right;"
                v-model="input.pajak"
                @input="hitungTotal"
                placeholder="0"
              />
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Pinalti</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <cleave
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="width: 150px; text-align: right;"
                v-model="input.pinalty"
                @input="hitungTotal"
                placeholder="0"
              />
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Total</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                placeholder="Total"
                style="width: 150px; text-align: right;"
                v-model="maskInput.total"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Keterangan</label>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <a-input
                placeholder="Keterangan"
                v-model="input.keterangan"
                :readOnly="false"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Cara Pencairan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-select class="" style="width: 200px;" v-model="input.cara">
                <a-select-option
                  v-for="(data, index) in allMaster.carapencairan"
                  :key="index"
                  :value="data.id"
                  >{{ data.keterangan }}</a-select-option
                >
              </a-select>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <!-- <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Golongan Deposan</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                placeholder="Golongan Deposan"
                v-model="maskInput.golongan_deposan"
                :readOnly="true"
              ></a-input>
            </div> -->
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Golongan Deposito</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                placeholder="Golongan Deposito"
                v-model="maskInput.golongan_deposito"
                :readOnly="true"
              ></a-input>
            </div>
            <!-- <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Penempatan Deposito</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                placeholder="Penempatan Deposito"
                v-model="maskInput.penempatan_deposito"
                style="width: 150px; text-align: right;"
                :readOnly="true"
              ></a-input>
            </div> -->
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Suku Bunga</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder="0.00"
                v-model="input.sukubunga"
                style="width: 70px; text-align: right;"
                :readOnly="true"
              ></a-input>
              %
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Jangka Waktu</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder="0"
                v-model="maskInput.jw"
                style="width: 70px; text-align: right;"
                :readOnly="true"
              ></a-input>
              Bulan
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Tgl Penempatan</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder="Tgl Penempatan"
                v-model="maskInput.tgl_penempatan"
                style="width: 130px;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Jth Tempo Deposito</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder="Jth Tempo Deposito"
                v-model="maskInput.tgl_jatuh_tempo"
                style="width: 130px;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Tgl Valuta Bulan Ini</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder="Tgl Valuta Bulan Ini"
                v-model="maskInput.tgl_valuta_bulanini"
                style="width: 130px;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-4">
              <label class="mt-1">Rek. Tabungan</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-4">
              <a-input
                class="mr-2"
                placeholder=""
                v-model="maskInput.rekening_tabungan"
                style="width: 200px;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Nama Nasabah</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder=""
                v-model="maskInput.nama_tabungan"
                :readOnly="true"
              ></a-input>
            </div>
            <!-- <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Alamat Nasabah</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder=""
                v-model="maskInput.alamat_tabungan"
                :readOnly="true"
              ></a-input>
            </div> -->
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Saldo Nasabah</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder=""
                v-model="maskInput.saldo_tabungan"
                style="width: 150px; text-align: right;"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">No. Belyet</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
              <a-input
                class="mr-2"
                placeholder=""
                v-model="maskInput.no_bilyet_deposito"
                style="width: 230px;"
                :readOnly="true"
              ></a-input>
            </div>
            <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
              <a-button
                class="btn btn-outline-primary float-right"
                style="min-width: 120px"
                @click="handleSubmit"
                >Submit</a-button
              >
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <a-table
            :key="componentKeyDatatable"
            :columns="columns"
            :dataSource="data"
            size="small"
            :scroll="{ y: 400 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 500,
              hideOnSinglePage: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span v-if="column.dataIndex === 'name'">
                  {{ text }}
                </span>
                <span v-else>
                  {{ text }}
                </span>
              </template>
            </template>
            <template slot="cetak" slot-scope="text, record">
              <a-button
                class="btn btn-outline-success"
                @click="reGenerateValidasi(record)"
              >
                <a-icon type="printer" />
              </a-button>
            </template>
            <label slot="tgl" slot-scope="text">
              {{ formatTanggal(text) }}
            </label>
            <template slot="Nominal" slot-scope="text">
              {{ formatCurrency(text) }}
            </template>
            <template slot="jumlah" slot-scope="text, record">
              {{
                record.debet !== 0
                  ? formatCurrency(record.debet)
                  : formatCurrency(record.kredit)
              }}
            </template>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import Cleave from 'vue-cleave-component'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    cleave: Cleave,
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      dataRekening: [],
      fetching: false,
      selectedName: [],
      componentKey: 0,
      componentKey1: 0,
      norekening: '',
      allowEditNominal: false,
      allowEditBunga: false,
      maskInput: {
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        no_bukti: '',
        golongan_deposan: '',
        golongan_deposito: '',
        penempatan_deposito: '',
        jw: '',
        tgl_penempatan: '',
        tgl_jatuh_tempo: '',
        tgl_valuta_bulanini: '',
        tgl_jatuh_tempo_bulanini: '',
        nominal: '',
        total: '',
        rekening_tabungan: '',
        nama_tabungan: '',
        alamat_tabungan: '',
        saldo_tabungan: '',
        no_bilyet_deposito: '',
      },
      input: {
        deposito_id: '',
        faktur: '',
        tgl: moment(),
        setoran: 0,
        pencairan: 'pokok',
        bunga: 0,
        pajak: '',
        pinalty: '',
        keterangan: '',
        sukubunga: '',
        kas: 0,
        cara: '',
        username: '',
        // Dari api

        // rekening: '',
        // jumlah_hari: '0',
        // cara_pencairan: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Property a-table
      componentKeyDatatable: 0,
      columns: [
        // {
        //   title: 'Cetak',
        //   width: 50,
        //   scopedSlots: {
        //     customRender: 'cetak',
        //   },
        // },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: { customRender: 'nomortransaksi' },
        },
        {
          title: 'Tanggal',
          width: 100,
          key: 'tgl',
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'Rekening',
          dataIndex: 'rekening',
          scopedSlots: { customRender: 'rekening' },
        },
        {
          title: 'Setoran',
          dataIndex: 'setoran',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Pencairan',
          dataIndex: 'pencairan',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Bunga',
          dataIndex: 'bunga',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Pajak',
          dataIndex: 'pajak',
          width: 70,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      // End of property
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    moment,
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet2('deposito_mutasi/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet2(
            'deposito_mutasi/cari?Rekening=' + nvalue1,
          )
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          tabungan_id: dataTarget.id,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
        this.input.deposito_id = dataTarget.id
        this.input.sukubunga = dataTarget.sukubunga
        this.maskInput = dataTarget
        this.maskInput.namanasabah = dataTarget.nama
        this.maskInput.alamatnasabah = dataTarget.alamat
        this.maskInput.golongan_deposito = dataTarget.nama_golongandeposito
        this.maskInput.rekening_tabungan = dataTarget.rekening_tabungan
        this.maskInput.tgl_penempatan = dataTarget.tgl
        this.maskInput.saldo_tabungan = lou.curency(
          dataTarget.saldo_tabungan,
        )
        this.maskInput.tgl_jatuh_tempo = dataTarget.jthtmp
        this.maskInput.no_bilyet_deposito = dataTarget.nobilyet
      }
    },
    hitungTotal() {
      // console.log('this.maskInput.nominal', this.maskInput.nominal)
      // console.log('this.input.bunga', this.input.bunga)
      // console.log('this.input.pajak', this.input.pajak)
      // console.log('this.input.pinalty', this.input.pinalty)
      if (this.input.pajak !== '' && this.input.pinalty !== '') {
        var nt =
          parseFloat(this.maskInput.nominal) +
          parseFloat(this.input.bunga) -
          parseFloat(this.input.pajak) -
          parseFloat(this.input.pinalty)
        this.maskInput.total = lou.curency(nt)
      }
      this.input.pencairan = this.maskInput.nominal
    },
    pencairanChange() {
      var thf = this.maskInput.pencairan
      // console.log('thf', thf)
      if (thf === 'pokok') {
        this.allowEditNominal = true
        this.allowEditBunga = false
      } else if (thf === 'bunga') {
        this.maskInput.nominal = 0
        this.allowEditNominal = false
        this.allowEditBunga = true
      } else {
        this.allowEditNominal = true
        this.allowEditBunga = true
      }
      this.componentKey += 1
    },
    async checkRekening1() {
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'deposito_mutasi/cari?Rekening=' + this.norekening.toString(),
        )
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = [res.data]
            var value = this.dataRekening.findIndex(
              (x) => x.rekening === this.norekening,
            )
            var dataTarget = this.dataRekening[value]
            var data = {
              selectedName: { key: 0, label: dataTarget.nama },
              selectedRekening: { key: 0, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              tabungan_id: dataTarget.id,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)

            this.input.deposito_id = dataTarget.id
            this.input.sukubunga = dataTarget.sukubunga
            this.maskInput = dataTarget
            this.maskInput.namanasabah = dataTarget.nama
            this.maskInput.alamatnasabah = dataTarget.alamat
            this.maskInput.golongan_deposito = dataTarget.nama_golongandeposito
            this.maskInput.rekening_tabungan = dataTarget.rekening_tabungan
            this.maskInput.tgl_penempatan = dataTarget.tgl
            this.maskInput.saldo_tabungan = lou.curency(
              dataTarget.saldo_tabungan,
            )
            this.maskInput.tgl_jatuh_tempo = dataTarget.jthtmp
            this.maskInput.no_bilyet_deposito = dataTarget.nobilyet
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // var res = await lou.customUrlGet2(
          //   'deposito_mutasi/cari?Rekening=' + this.norekening,
          // )
          // if (res) {
          //   if (res.data !== null) {
          //     this.selectedName = { key: 0, label: res.data.nama }
          //     this.input.deposito_id = res.data.id
          //     this.input.sukubunga = res.data.sukubunga
          //     this.maskInput = res.data
          //     this.maskInput.namanasabah = res.data.nama
          //     this.maskInput.alamatnasabah = res.data.alamat
          //     this.maskInput.golongan_deposito = res.data.nama_golongandeposito
          //     this.maskInput.rekening_tabungan = res.data.rekening_tabungan
          //     this.maskInput.tgl_penempatan = res.data.tgl
          //     this.maskInput.saldo_tabungan = lou.curency(res.data.saldo_tabungan)
          //     this.maskInput.tgl_jatuh_tempo = res.data.jthtmp
          //     this.maskInput.no_bilyet_deposito = res.data.nobilyet
          //   }
        }
      }
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      var res = await lou.customUrlGet2('deposito_mutasi')
      if (res) {
        this.data = res.data
      }
    },
    rekeninglimit(from, limit, toref) {
      if (this.maskInput[from].length >= limit) {
        this.$refs[toref].$el.focus()
      }
    },
    async handleSubmit() {
      Modal.confirm({
        title: 'Apakah anda yakin data sudah benar?',
        content: 'Data tidak dapat di edit ataupun di hapus nanti!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var fd = this.$g.clone(this.input)

          fd.tgl =
        this.input.tgl !== null ? this.input.tgl.format('YYYY-MM-DD') : null
          var res = await lou.customUrlPost2('deposito_mutasi', fd)
          if (res) {
            this.data.push(res.data)
            this.resetForm()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    resetForm() {
      this.maskInput = {
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        no_bukti: '',
        golongan_deposan: '',
        golongan_deposito: '',
        penempatan_deposito: '',
        jw: '',
        tgl_penempatan: '',
        tgl_jatuh_tempo: '',
        tgl_valuta_bulanini: '',
        tgl_jatuh_tempo_bulanini: '',
        nominal: 0,
        total: '',
        rekening_tabungan: '',
        nama_tabungan: '',
        alamat_tabungan: '',
        saldo_tabungan: '',
        no_bilyet_deposito: '',
      }
      this.input = {
        deposito_id: '',
        faktur: '',
        tgl: '',
        setoran: 0,
        pencairan: 'pokok',
        bunga: 0,
        pajak: '',
        pinalty: '',
        keterangan: '',
        sukubunga: '',
        kas: 0,
        cara: '',
        username: '',
        // Dari api
      }
      this.$refs.rekening.$el.focus()
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
